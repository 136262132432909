import React from 'react';

import styles from './information.module.css';
import icon from '../images/paw-bubbles.svg';

const Information = () => {
    return (
        <section id="info" className={styles.info}>
            <ul className={styles.info__list}>
                <li className={styles.info__item}>Additional costs may apply if extra time is spent due to the temperament of the dog or heavily matted dogs.
                    <span className={styles.info__span}><img className={styles.info__icon} src={icon} alt="dog bath bubbles"/></span>
                </li>
                <li className={styles.info__item}>Every step will be taken to ensure your dog is not subjected to any undue pain and discomfort while de-matting.
                    <span className={styles.info__span}><img className={styles.info__icon} src={icon} alt="dog bath bubbles"/></span>
                </li>
                <li className={styles.info__item}>If the coat is heavily matted it may be in the best interest of the dog to remove the coat.
                    <span className={styles.info__span}><img className={styles.info__icon} src={icon} alt="dog bath bubbles"/></span>
                </li>
                <li className={styles.info__item}>Please allow 2 - 3 hours for the full grooming process.
                    <span className={styles.info__span}><img className={styles.info__icon} src={icon} alt="dog bath bubbles"/></span>
                </li>
                <li className={styles.info__item}>Be sure that your dog's best interest, comfort and happiness are our #1 priority.
                    <span className={styles.info__span}><img className={styles.info__icon} src={icon} alt="dog bath bubbles"/></span>
                </li>
                <li className={styles.info__item}>For more information, you can also email us at julie.pupsnsuds@gmail.com</li>
            </ul>
        </section>
    );
}

export default Information;