import React from 'react';
import HeaderSmall from '../components/HeaderSmall';
import Rates from '../components/Rates';
import Information from '../components/Information';
import Services from '../components/Services';
import Layout from '../layouts/index';

const RatesPage = () => {

    return (
        <Layout>
            <HeaderSmall siteTitle="Pups N Suds Dog Grooming"/>
            <Rates />
            <Information />
            <Services />
        </Layout>
    );
}

export default RatesPage