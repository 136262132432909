import React from 'react';

import styles from './infoTile.module.css';

const InfoTile = (props) => {
    const { icon, heading, description, price, extra, note } = props;
    
    const iconSpan = icon ? <span className={styles.featureIcon}><img src={icon} alt="" /></span> : null;
    const headH2 = heading ? <h2 className={styles.h2}>{heading}</h2> : null;
    const descP = description ? <p className={styles.p}>{description}</p> : null;
    const priceH3 = price ? <h3 className={styles.h3}>{price}</h3> : null;
    const extraH5 = extra ? <h5 className={styles.h5}>{extra}</h5> : null;
    const noteP = note ? <p className={styles.noteP}>{note}</p> : null;

    return (
        <div className={styles.feature}>
            {iconSpan}
            {headH2}
            {descP}
            {priceH3}
            {extraH5}
            {noteP}
        </div>
    );
}

export default InfoTile;