import React from 'react';
import InfoTile from './InfoTile';

import paw from '../images/icons/white-paw-print.svg';
import nails from '../images/icons/canine-pawprint.svg';
import hairdryer from '../images/icons/hairdryer-and-comb.svg';
import lotion from '../images/icons/pet-lotion.svg';
import scissors from '../images/icons/scissors.svg';
import swab from '../images/icons/cotton-swab.svg';
import icon from '../images/paw-bubbles.svg';
import styles from './services.module.css'

const Services = () => {
    return (
        <section id="services" className={styles.bg}>
            <div className="container">
                <h2 className="title-underline dark-grey mb-3 ml-3">Our Services</h2>
                <div className={styles.container}>
                    <InfoTile icon={paw} heading="Complete Skin Analysis" description="Check your pups skin for any conditions that can indicate underlying health problems." />
                    <InfoTile icon={scissors} heading="Brush out and/or Clipping" description="Get rid of pesky mats and have your dog's coat looking and feeling fresh again." />
                    <InfoTile icon={swab} heading="Cleaning of the ears" description="Gently clean your pups ears with medicated cotton balls and remove excess hair from the canal to avoid infections." />
                    <InfoTile icon={nails} heading="Trimming of the Nails" description="Keep your pups nails nice and short to prevent painful breaks, and to keep it comfy for those pup and human cuddles." />
                    <InfoTile icon={lotion} heading="Bathing with our Hypoallergenic Shampoo" description="Pups n' Suds use only the best hypoallergenic shampoos to avoid irritation." />
                    <InfoTile icon={hairdryer} heading="Velocity Dried and Re-Brushed" description="We use only room temperature air to dry your pup so there's no chance of them overheating." />
                    <div className={styles.heading__wrap}>
                        <div className={styles.icons}><img className={styles.img} src={icon} alt="dog bath bubbles"/></div>
                        <h2 className={styles.heading}>We also offer special treatments</h2>
                        <div className={styles.icons}><img className={styles.img} src={icon} alt="dog bath bubbles"/></div>
                    </div>
                    <InfoTile icon="" heading="Flea and Skunk Baths" description="Get sprayed? Dog been itching? We offer skunk and flea baths for these times." note="** Please notify us if your dog has fleas." />
                    <InfoTile icon="" heading="Medicated Shampoos for Dry, Itchy Coats" description="If your pup has particularly sensitive skin or skin conditions we offer a medicated shampoo." />
                </div>
            </div>
        </section>
    );
}

export default Services;