import React from 'react';
import InfoTile from './InfoTile.js';
import xl from '../images/dog-xl.svg';
import lg from '../images/dog-lg.svg';
import md from '../images/dog-md.svg';
import sm from '../images/dog-sm.svg';

import styles from './Rates.module.css';

const Rates = () => {
    return (
        <section id="rates" className={styles.bg}>
            <div className="container">
                <h2 className="title-underline dark-grey mt-3 mb-3 ml-3">Rates</h2>
                <div className={styles.wrap}>
                    <InfoTile icon={xl} heading="X-Large" description="Berness Mountain Dog, etc." price="$110 and up" extra="Plus HST" />
                    <InfoTile icon={lg} heading="Large" description="Goldens, Airedales, etc." price="$90 and up" extra="Plus HST" />
                    <InfoTile icon={md} heading="Medium" description="Cocker Spaniel, Cockapoo, Shih Tzu, etc." price="$70 and up" extra="Plus HST" />
                    <InfoTile icon={sm} heading="Small" description="Yorkshire Terrier, etc." price="$50 - 60" extra="Plus HST" />

                    <div className={styles.feature_extras}>
                        <table className={styles.table}>
                            <tbody>
                                <tr className={styles.tr}>
                                    <td className={styles.td}>Medicated Shampoos:</td>
                                    <td className={styles.tdRight}>$5.00</td>
                                </tr>
                                <tr className={styles.tr}>
                                    <td className={styles.td}>Nail Trimming only:</td>
                                    <td className={styles.tdRight}>$10.00</td>
                                </tr>
                                <tr className={styles.tr}>
                                    <td className={styles.td}>Paw Trimming &amp; Nails only:</td>
                                    <td className={styles.tdRight}>$15.00</td>
                                </tr>
                                <tr className={styles.tr}>
                                    <td className={styles.td}>Flea Baths:</td>
                                    <td className={styles.tdRight}>$20.00</td>
                                </tr>
                                <tr className={styles.tr}>
                                    <td className={styles.td}>Skunk Baths:</td>
                                    <td className={styles.tdRight}>$20.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Rates;